body {
  margin: 0;
  font-family: Public Sans, sans-serif, -apple-system, blinkmacsystemfont,
    Segoe UI, roboto, Helvetica Neue, arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', Segoe UI Symbol;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 215, 215);
  border-radius: 50px;
}
