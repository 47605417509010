.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* set varriant style */

:root {
  --color-primary: #33303cde !important;
  --color-secondary: #33303cad !important;
  --bgr-primary: linear-gradient(225deg, #8a3cff 0%, #563981 100%) !important;
  --bgr-secondary: #28c78f !important;
  --color-background-linear: linear-gradient(225deg, #7367f0 0%, #563981 100%);
}


/* style global */

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.bgr-global {
  background: linear-gradient(225deg, #8a3cff 0%, #563981 100%) !important;
}

.fw-bold {
  font-weight: bold !important;
}

.bd-radius {
  border-radius: 6px !important;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.icons-style {
  width: 50px !important;
  height: 50px !important;
  color: #fc8a31;
}

.input-views fieldset {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.input-views label {
  color: #00000099 !important;
}

.tree-item-label div {
  font-size: 1.28rem !important;
  color: #33303cde !important;
  font-weight: 600 !important;
}

.upperCase textarea {
  text-transform: uppercase !important;
}


/* override style MUI */

.input-select div div {
  padding: 8.5px 14px !important;
  display: flex !important;
  align-items: center;
}

.box-none {
  box-shadow: none !important;
}

.padding-24px {
  padding: 0 24px !important;
}

.padding-bottom {
  padding-bottom: 8px !important;
}

.padding-24px-0 {
  padding: 0 24px !important;
}

.css-19kzrtu {
  padding: 24px 0 0 0 !important;
}

.tranform-none div div button {
  text-transform: none !important;
}

.font-sideBar span {
  font-size: 0.9rem !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 14px !important;
}